<template>
  <body>
    <div class="container">
      <nav
        class="navbar navbar-expand-lg navbar-light bg-light shadow fixed-top"
      >
        <div class="container-fluid">
          <a class="navbar-brand" href="#">THE TIGER TEAM ACADEMY</a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-lg-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#about">About</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#Founder">Founders</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#Contact">Contact</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">Service</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <header
      class="masthead"
      style="
        background-image: url('https://www.img.in.th/images/3df59849f1b8ff5367181dd7edad6c59.png');
      "
    >
      <div class="container h-100">
        <div class="row h-100 align-items-center">
          <div class="col-12 text-center">
            <h1 class="text-white Supa animated animatedFadeInUp fadeInUp">
              Move Thailand <br />
              Change The World
            </h1>
          </div>
        </div>
      </div>
    </header>

    <!-- <section id="section2">
      <div class="container-xxl py-6-2">
        <div class="container">
          <div class="row g-5">
            <div class="col-lg-6">
              <div class="row img-twice position-relative h-100">
                <div class="col-6" data-aos="fade-up" data-aos-duration="2000">
                  <img
                    class="img-fluid rounded"
                    src="https://th.bing.com/th/id/R.d42708ef67bd4b55a9ff29346cfa9a62?rik=3fe1CAqzwbZJZg&pid=ImgRaw&r=0"
                  />
                </div>
                <div
                  class="col-6 align-self-end"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <img
                    class="img-fluid rounded"
                    src="https://i.pinimg.com/originals/78/cc/58/78cc58169215ab9145cbfbb46801ed7d.jpg"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div
                class="h-100"
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1500"
              >
                <p class="text-primary text-uppercase mb-2">// WHY CHOOSE US</p>
                <h1 class="display-6 mb-4">The Tiger Team Academy</h1>
                <p></p>
                <p></p>
                <div class="row g-2 mb-4">
                  <div class="col-sm-6">
                    <i class="fa text-primary me-2"> </i>
                    "Quality Products"
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <section class="makhan" id="about">
      <div class="container-1">
        <div class="left"></div>
        <div class="right">
          <div class="content" data-aos="zoom-in" data-aos-duration="2000">
            <h2>
              The Tiger <br />Team Academy
              <p>"About"</p>
            </h2>
            <p>
              The Tiger
Team Academy
"About"
The Tiger Team Academy put great emphasis on grooming our fellow students to become entrepreneurs in technology business through direct involvement with innovation - driven research projects with famous university faculty members , and also with our affiliated companies in knowledge - based business to solve real problems of the industry . The folks at The Tiger Team Academy have the unparalleled opportunities to learn firsthand the life and soul of a startup in deep technology entrepreneurship . 

The mission of The Tiger Team Academy is also to provide support , unmatched anywhere , for our folks to further their studies abroad with the top technology universities of the world , both college and graduate education , so that our fellow students could take on the up and coming technologies in developing their entrepreneurship to give back to the society in a big way in the relentless pursuit of our vision to "Move Thailand TH Change the World" 
            </p>
          </div>
        </div>
      </div>
    </section>

    <section> 
      <div class="container">
        <div class="row" style="padding-top:20px">
            <div class="col">
             <img width="75%" src="../assets/Building.gif" alt="">   
            </div>
            <div class="col post">
              <hr width="270">
              <h2 style="display: inline;"> Vision and Mission</h2>
              <p>The truth of the matter is that the folks at the academy — with these unparalleled opportunities 
                — are working extremely hard to upgrade Thailand from a factor-and-efficiency-driven to an 
                innovation-driven economy so that Thailand could one day produce knowledge-based instead of commodity-based products for the world.
                They are challenged to both study and work to experience firsthand the life of a startup.
                <span id="dots">...</span><span id="more"> They are groomed to further their studies abroad to acquire technology transfer and connection for foreign investment.
                Their lives have been turning upside down at the academy — from provincial youngsters with parochial outlook to, 
                hopefully and excitedly, become the next generation leaders with global perspective. 
                They are groomed to become very successful and give back to the world in a big way like the song 
                “We are the World”. Everyone is working around the clock, one hundred hours a week, to make it happen under enormous pressure.
                In the past, there were many coming to join us, and although some could survive here because 
                they would be determined to dare mighty things, and they would put their lives and souls into our shared vision, 
                and they would live and breathe our noble mission — the winners, others failed because they were deep down crooks, 
                or they did not really know themselves — the whiners.</span>
              </p>
            </div>

        </div>

      </div>
    </section>
    
    
    
    
    
    
    <section id="section2.1">
      <div class="container-fluid py-6">
        <hr>
        <div class="container">
          <div class="row g-5">
            <div
              class="col-lg-6"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              <p class="text-warning text-uppercase mb-2"></p>
              <h1 class="display-6 mb-4">What Do We Offer For You?</h1>
              <div
                class="row gy-5 gx-4"
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1500"
              >
                <div class="col-sm-6">
                  <div class="d-flex align-items-center mb-3">
                    <div
                      class="
                        flex-shrink--0
                        btn-square
                        rounded-circle
                        me-3
                      "
                    >
                      <i class="fa-solid fa-briefcase" style="color:white"></i>
                    </div>
                    <h5 class="mb-0">Entrepreneur</h5>
                  </div>
                  <span
                    >Want to create entrepreneurs who have the ability to develop the country</span
                  >
                </div>
                <div class="col-sm-6">
                  <div class="d-flex align-items-center mb-3">
                    <div
                      class="
                        flex-shrink--0
                        btn-square
                        rounded-circle
                        me-3
                      "
                    >
                      <i class="fa-solid fa-user-graduate" style="color:white"></i>
                    </div>
                    <h5 class="">Education</h5>
                  </div>
                  <span
                    >Want to create personnel who are competent in language and technology, so that can study at the world's leading universities.</span
                  >
                </div>
                <div class="col-sm-6">
                  <div class="d-flex align-items-center mb-3">
                    <div
                      class="
                        flex-shrink--0
                        btn-square
                        rounded-circle
                        me-3
                      "
                    >
                      <i class="fa-solid fa-school" style="color:white"></i>
                    </div>
                    <h5 class="mb-0">Secure</h5>
                  </div>
                  <span>Build a strong corporate culture</span>
                </div>
                <div class="col-sm-6">
                  <div class="d-flex align-item-center mb-3">
                    <div
                      class="
                        flex-shrink--0
                        btn-square
                        rounded-circle
                        me-3
                      "
                    >
                      <i class="fa-solid fa-hands-holding-child" style="color:white"></i>
                    </div>
                    <h5 class="mb-0">Endless Giving</h5>
                  </div>
                  <span
                    >Bring the knowledge gained, Create benefits back to society</span
                  >
                </div>
              </div>
            </div>
            <div class="col-lg-6" data-aos="fade-up" data-aos-duration="3000">
              <div class="row img-twice position-relative h-100">
                <br />
                <div class="col-6">
                  <img 
                    class="img-fluid rounded"
                    src="../assets/coding-g86e9d1a55_640.jpg"
                    alt=""
                    
                  />
                </div>
                <div class="col-6 align-self-end">
                  <img width="100%"
                    class="img-fluid rounded"
                    src="../assets/startup.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="team" id="Founder">
      <div class="team-area">
        <div class="container">
          <div class="title-team">
            <h1>The Three Founders</h1>
          </div>
          <div class="row">
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div
                class="single-team"
                data-aos="fade-down"
                data-aos-duration="1000"
              >
                <div class="img-area">
                  <img src="../assets/1.png" class="img-responsive" />
                </div>
                <div class="img-text">
                  <h4>
                    Assoc. Prof. Dr. Thanit Pewnim
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div
                class="single-team"
                data-aos="fade-down"
                data-aos-duration="2000"
              >
                <div class="img-area">
                  <img src="../assets/2.png" class="img-responsive" />
                </div>
                <div class="img-text">
                  <h4>
                    Wiraj Sripattanasakul
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div
                class="single-team"
                data-aos="fade-down"
                data-aos-duration="3000"
              >
                <div class="img-area">
                  <img src="../assets/3.png" class="img-responsive" />
                </div>
                <div class="img-text">
                  <h4 style="font-size:29px">
                    Assoc. Prof. Dr. Worawat Meevasana
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div id="welfaer" style="background-color: white; padding-top: 20px; padding-bottom: 50px;">
        <div class="container">
          <div class="row pt-2">
            <h1 class="pl-5">Things of support</h1>
          </div>
          <div class="row">
            <div class="col-lg-4 p-0 pl-3">
              <div class="card border-0 card-tiger-bg-picture">
                <div
                  class="card-body"
                  style="background: #C2c6ce; min-height: 250px"
                >
                  <h5 class="card-title">
                    <span
                      class="title-tiger-welfare"
                      style="color: #304a6e"
                      >Entrepreneur</span
                    >
                  </h5>
                  <p
                    class="card-text ti-4"
                    style="color: white; text-align: left"
                  >
                    "Acquire entrepreneurial knowledge from people with first-hand experience and affiliated companies"

                  </p>
                </div>
                <div data-aos="fade-left" data-aos-duration="1000">
                  <img
                    src="../assets/S52.jpg"
                    alt=""
                    class="card-img-top"
                    style="height: 250px"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-4 p-0">
              <div class="card border-0 card-tiger-bg-picture-bottom">
                <div data-aos="fade-left" data-aos-duration="2000">
                  <img
                    src="../assets/S54.jpg"
                    alt=""
                    class="card-img-top"
                    style="height: 250px"
                  />
                </div>
                <div
                  class="card-body"
                  style="background: #304a6e; min-height: 250px"
                >
                  <h5 class="card-title">
                    <span class="title-tger-welfaer" style="color: white"
                      >Innovation and Technology</span
                    >
                  </h5>
                  <p
                    class="card-text ti-4"
                    style="color: rgb(134, 142, 150); text-align: left"
                  >
                    "Acquire knowledge of innovation and cutting-edge technology and experience it up close".
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 p-0 pr-3">
              <div class="card border-0 card-tiger-bg-picture">
                <div
                  class="card-body"
                  style="background: #C2c6ce; min-height: 250px"
                >
                  <h5 class="card-title">
                    <span
                      class="title-tiger-welfaer"
                      style="color: #304a6e"
                      >Language</span
                    >
                  </h5>
                  <p
                    class="card-text ti-4"
                    style="color: white; text-align: left"
                  >
                    "Acquire knowledge of foreign languages along with knowledge of economics. From a businessman with first-hand experience in foreign affairs."
                  </p>
                </div>
                <div data-aos="fade-left" data-aos-duration="2000">
                  <img
                    src="../assets/S53.jpg"
                    alt=""
                    class="card-img-top"
                    style="height: 250px"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-4 p-0 pl-3">
              <div class="card border-0 card-tiger-bg-picture">
                <div
                  class="card-body"
                  style="background: #C2c6ce; min-height: 250px"
                >
                  <h5 class="card-title">
                    <p
                      class="title-tiger-welfare"
                      style="color: #304a6e"
                    >
                      Income During Study
                    </p>
                  </h5>
                  <p
                    class="card-text ti-4"
                    style="color: white; text-align: left"
                  >
                    "ได้รับทุนค่าครองชีพเมื่อมีความสามารถตามที่บริษัทในเครือกำหนด"
                  </p>
                </div>
                <div data-aos="fade-left" data-aos-duration="2000">
                  <img
                    src="../assets/S56.jpg"
                    alt=""
                    class="card-img-top"
                    style="height: 250px"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-4 p-0">
              <div class="card border-0 card-tiger-bg-picture-bottom">
                <div data-aos="fade-left" data-aos-duration="2000">
                  <img
                    src="../assets/S51.jpg"
                    alt=""
                    class="card-img-top"
                    style="height: 250px"
                  />
                </div>
                <div
                  class="card-body"
                  style="background: #304a6e; min-height: 250px"
                >
                  <h5 class="card-title">
                    <p class="title-tger-welfaer" style="color: white">
                      Music And Art
                    </p>
                  </h5>
                  <p
                    class="card-text ti-4"
                    style="color: rgb(134, 142, 150); text-align: left"
                  >
                    "Recreational from playing music and art, which can be applied to the work."
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 p-0 pr-3">
              <div class="card border-0 card-tiger-bg-picture">
                <div
                  class="card-body"
                  style="background: #C2c6ce; min-height: 250px"
                >
                  <h5 class="card-title">
                    <p
                      class="title-tiger-welfaer"
                      style="color: #304a6e"
                    >
                      Accommodation And Food
                    </p>
                  </h5>
                  <p
                    class="card-text ti-4"
                    style="color: white; text-align: left"
                  >
                    Fully maintained accommodation and food."
                  </p>
                </div>
                <div data-aos="fade-left" data-aos-duration="2000">
                  <img
                    src="../assets/S55.jpg"
                    alt=""
                    class="card-img-top"
                    style="height: 250px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section7" id="Contact">
      <div class="container">
        <div class="content7">
          <div class="left-side">
            <div class="address details">
              <i class="fas fa-map-marker-alt"></i>
              <div class="topic">Address</div>
              <div class="text-one">
                <p style="margin-bottom:0px">
                  481 Ban Pong Dinsor, Village No.15, Khok Kruat Sub-district, Mueang Nakhon Ratchasima District, Nakhon Ratchasima Province, 30280
                </p>
              </div>
            </div>

            <div class="email details">
              <a href="https://www.facebook.com/The-Tiger-Team-Academy-102267749127138">
              <i class="fa-brands fa-facebook"></i>
              </a>
              <div class="topic">Facebook</div>
              <div class="text-one">The Tiger Team Academy</div>
            </div>

            <div class="phone details">
              <i class="fas fa-phone-alt"></i>
              <div class="topic">Phone</div>
              <div class="text-one">061-096-3992</div>
              <div class="text-two">Nitikorn</div>
            </div>

            <div class="email details">
              <i class="fas fa-envelope"></i>
              <div class="topic">Email</div>
              <div class="text-one">nitikornchumnankul@gmail.com</div>
            </div>

          </div>
          <div class="right-side">
            <div class="topic-text">Contact us</div>
            <p style="font-size:17px">
             <b>If you have any questions, you can get in touch with us. We'll be happy to help you, we'll get back to you as soon as possible.</b>
            </p>

            <form action="">
              <div class="form-group">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d234.10305051632733!2d101.99830432271006!3d14.875109438625106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311ead95f6d07fbf%3A0x91bfe22719b7265f!2sThe%20Tiger%20Team%20Academy!5e1!3m2!1sth!2sth!4v1656490134396!5m2!1sth!2sth"
                  width="100%"
                  height="350"
                  style="border: 0; border-radius: 8px; max-width: auto;"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
                <!-- <input type="text" name="name" required="required" class="form-control" placeholder="Enter your name"> -->
              </div>
              <div class="form-group">
                <!-- <input type="email" name="email"  required=" required" class="form-control" placeholder="Enter your Email" > -->
              </div>

              <div class="form-group">
                <!-- <input type="text" name="phone" required=" required" class="form-control" placeholder="Enter your number " > -->
              </div>
              <div class="form-group">
                <!-- <textarea name="message" id="exampleFormControlTextarea1"  
             rows="5" class="form-control" placeholder="Enter your massage"></textarea> -->
              </div>
              <!-- <button type="submit" class="btn" >SEND MESSAGE</button> -->
            </form>
          </div>
        </div>
      </div>
    </section>
    <footer>
      <div class="container">
        <div class="row">

        </div>
      </div>
    </footer>
  </body>
</template>

<script>
export default {
  name: "HeadeR",
  props: {
    msg: String,
  },
};
</script>

<style>
:root {
  --primary: #eaa636;
  --secondary: #545454;
  --light: #fdf5eb;
  --dark: #1e1916;
}

@import url("https://fonts.googleapis.com/css2?family=Bungee&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fugaz+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Geo&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Tinos:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@300;400;500;600&display=swap');
@import url('https://db.onlinewebfonts.com/c/f3258385782c4c96aa24fe8b5d5f9782?family=Old+English+Text+MT');

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Kanit', sans-serif;
  padding-top: 45px;
  margin: 0;
  line-height: 1.6;
  text-align: left;
}

.masthead {
  width: 100%;
  height: 100vh;
  min-height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background: no-repeat center center;
  background-color: #f6f8f9;
  background-attachment: fixedอ;
  position: relative;
  background-size: cover;
}

header {
  position: absolute;
  z-index: 10;
  width: 100%;
}

.navbar {
  padding: 20px 0;
}

.navbar-brand {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 2em;
  color: blue;
  font-family: "Old English Text MT"; src: url("//db.onlinewebfonts.com/t/f3258385782c4c96aa24fe8b5d5f9782.eot"); 
  src: url("//db.onlinewebfonts.com/t/f3258385782c4c96aa24fe8b5d5f9782.eot?#iefix") format("embedded-opentype"), 
  url("//db.onlinewebfonts.com/t/f3258385782c4c96aa24fe8b5d5f9782.woff2") format("woff2"), 
  url("//db.onlinewebfonts.com/t/f3258385782c4c96aa24fe8b5d5f9782.woff") format("woff"), 
  url("//db.onlinewebfonts.com/t/f3258385782c4c96aa24fe8b5d5f9782.ttf") format("truetype"), 
  url("//db.onlinewebfonts.com/t/f3258385782c4c96aa24fe8b5d5f9782.svg#Old English Text MT") format("svg");
}

.nav-item {
  font-weight: 800;
  text-transform: uppercase;
}

.navbar-light .navbar-nav .nav-item .nav-link:hover,
.navbar-light .navbar-nav .nav-item.active .nav-link {
  background: #285384;
  color: rgb(255, 255, 255);
}

.navbar-light .navbar-nav .nav-item .nav-link {
  color: #262626;
  padding: 5px 15px;
}

.navbar-light .navbar-toggler {
  border: none;
  color: #fff;
  border: 2px solid #7f7f7f;
  border-radius: 0;
  outline: none;
}

.section-padding {
  padding: 100px 0;
}

.Supa {
  font-size: 72px;
  text-shadow: 2px 1px 3px rgba(251, 241, 223, 1);
  font-family: 'ChomskyRegular';
   font-weight: normal;
   font-style: normal;
}

.team {
  background: #c2c6ce;
}

.team-area {
  padding-top: 5%;
}

.single-team {
  box-shadow: 0px 7px 7px 2px rgba(0, 0, 0, 0.1);
  background-color: white;
  margin-bottom: 10px;
  margin-left: 10px;
}
.title-team {
  text-align: center;
  padding-bottom: 20px;
}

.img-area {
  overflow: hidden;
  position: relative;
}
.img-area img {
  width: 100%;
}
.img-area:hover img {
  transform: scale(1.2);
}
.img-area img {
  transition: all 0.4s ease 0s;
  widows: 100%;
}
.img-text {
  padding: 25px;
  color: black;
  text-align: center;
}
.img-text h4 {
  margin: 0 0 5px;
  font-size: 30px;
  letter-spacing: 5px;
}
.img-text h5 {
  font-size: 17px;
  color: #545154;
}
.card-tiger {
  background-color: rgb(255, 212, 152);
}
.p-3 {
  padding: 1rem !important;
}

.card-tiger-bg-about {
  background-color: #c19b5a !important;
  color: white;
}

.btn-readmore-tiger {
  background-color: #7a643d;
  color: white;
  border: 1px solid white;
}

.card-branner-left-right {
  max-height: -webkit-fill-available;
}

.card-img-top {
  width: 100%;
  object-fit: cover;
}

.card-body {
  -webkit-box-flex: 1;
  padding: 1.25rem;
}

hr.hr-card-tiger {
  border-top: 2px solid yellow;
}

.ti-4 {
  text-indent: 40px;
}

.btn-readmore-history-2-tiger {
  background: rgb(68, 68, 68);
  color: white;
  border: 1px solid white;
  border-radius: 12px;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.float-right {
  float: right !important;
}

.makhan {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c2c6ce;
}

.container-1 {
  width: 90%;
  max-width: 1000px;
  margin: 50px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left {
  width: 50%;
  height: 600px;
  background: url(../assets/coding-g77a5bbf0f_1280.jpg);
  border-radius: 8px;
}

.right {
  width: 50%;
  min-height: 400px;
  background-color: #304a6e;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  border-radius: 8px;
  color: white;
  margin-left: -150px;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
}

.animatedFadeInUp {
  opacity: 0;
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

.right h2 {
  font-size: 40px;
  font-weight: lighter;
  color: #fff;
}

.right span {
  font-size: 30px;
  font-weight: lighter;
  color: #26231f;
}

.right p {
  margin: 20px 0;
  font-weight: 500;
  line-height: 25px;
  color: #fff;
}
@media only screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    width: 100%;
    margin: 0 20px;
  }
  .left {
    width: 100%;
    height: 400px;
  }
  .right {
    width: 90%;
    margin: 0;
    margin-top: -100px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.section7 {
  min-height: 100vh;
  width: 100%;
  display: flex;
  background: #c2c6ce;
  align-items: center;
  justify-content: space-between;
}

.content7 {
  width: 100%;
  display: flex;
  background: #fff;
  border-radius: 15px;
  padding: 20px 60px 30px 40px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.left-side {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  position: relative;
}

.left-side::before {
  content: "";
  position: absolute;
  height: 70%;
  width: 2px;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  background: rgb(172, 253, 172);
}

.left-side .details {
  margin: 14px;
  text-align: center;
}

.left-side .details i {
  font-size: 20px;
  color: #285384;
  margin-bottom: 10px;
}

.left-side .details .topic {
  font-size: 15px;
  font-weight: 500;
}

.left-side .details .text-one,
.left-side .details .text-two {
  font-size: 14px;
  color: #afafb6;
}

.right-side {
  width: 75%;
  margin-left: 75px;
}

.topic-text {
  font-size: 35px;
  color: #304a6e;
}

.form-group input,
.form-group textarea {
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  border-style: dashed;
  font-size: 18px;
  background: #e8ebf7;
  border-radius: 6px;
  padding: 0 15px;
  resize: none;
}

.btn {
  background-color: #f8ba72;
  color: #fff;
  padding: 9px 15px;
  border-radius: 6px;
}

@media (max-width: 950px) {
  .container {
    width: 90%;
    padding: 30px 40px 40px 35px;
  }
  .content .right-side {
    width: 75%;
    margin-left: 55px;
  }
}

@media (max-width: 820px) {
  .content {
    flex-direction: column-reverse;
  }
  .content .left-side {
    width: 100%;
    flex-direction: row;
    margin-top: 40px;
    justify-content: center;
    flex-wrap: wrap;
  }
  .content .left-side::before {
    display: none;
  }
  .content .right-side {
    width: 100%;
    margin-left: 0;
  }
}

.py-6 {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: #fff;
}
.py-6-2 {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: rgb(255, 217, 236);
}
.img-twice::before {
  position: absolute;
  content: "";
  width: 60%;
  height: 80%;
  top: 10%;
  left: 20%;
  background: #315884;
  border: 25px solid #87b6f99d;
  border-radius: 6px;
  z-index: -1;
}
.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}
.btn-square {
  width: 38px;
  height: 38px;
  background-color: #285384;
}
h4,
h5,
h6,
.h4,
.h5,
.h6 {
  font-weight: 600 !important;
}

</style>