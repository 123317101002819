<template>
<div class="image w-100 vh-100 d-flex align-items-center justify-content-center">
    <div class="text-center">
        <h2 class="text-white fw-light" style="font-size: 48px;">OUR WEBSITE IS..</h2>
        <h1 class="text-white fw-bold" style="font-size: 82px">COMING SOON</h1>
      </div>
</div>
</template>

<script>
export default {

}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300;700&display=swap');


html,body { padding: 0 !important;}

.image {
  background-image: url('../src/assets/ComingSoon2.png');
  background-size: cover;
}
</style>